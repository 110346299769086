import 'react';
import React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    state = {
        isOpen: false,
    };

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm bg-white" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/" className="pl-0">
                            <img
                                src="/images/Stronghold_Support_Logo.png"
                                className="my-2"
                                alt="Stronghold Logo"
                                style={{ height: 40 }}
                            />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav mr-auto"></ul>
                            <ul className="navbar-nav">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/sdk">
                                        SDK
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/paylink">
                                        PayLink
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/use-cases">
                                        Use Case
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <a
                                        className="nav-link text-dark"
                                        href="https://docs.strongholdpay.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        API Docs
                                    </a>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };
}
