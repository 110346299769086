import { connect, MapStateToPropsParam } from 'react-redux';
import React from 'react';
import { ApplicationState, actionCreators } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import Loader from '../components/Loader';

interface StateProps {}

interface DispatchProps {
    tryPayLink: typeof actionCreators.payLink.tryPayLink;
}

type Props = StateProps & DispatchProps;

class PayLinkTryIt extends React.PureComponent<Props> {
    componentDidMount() {
        this.props.tryPayLink();
    }

    render() {
        return (
            <div className="text-center h-100 d-flex justify-content-center align-items-center">
                <Loader size="md" color="primary" />
            </div>
        );
    }
}
const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators.payLink, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayLinkTryIt);
