import React from 'react';

export default function ReferenceLink(
    props: React.PropsWithChildren<{
        className?: string;
        to: 'api_docs' | 'marketing' | 'dashboard';
        route?: string;
    }>,
) {
    let href = 'https://docs.strongholdpay.com';
    let children: React.ReactNode | string = 'API Docs';

    switch (props.to) {
        case 'dashboard':
            href = 'https://dashboard.strongholdpay.com';
            children = 'Dashboard';
            break;
        case 'marketing':
            href = 'https://stronghold.co';
            children = 'Stronghold';
            break;
    }

    children = props.children || children;
    if (props.route) {
        href = `${href}/${props.route}`;
    }

    return (
        <a className={props.className} href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );
}
