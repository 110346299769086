import { connect } from 'react-redux';
import React from 'react';
import SideBySideLayout from '../components/SideBySideLayout';
import LeftSide from '../components/LeftSide';
import { Anchor, TableOfContents, TableOfContentsElement } from '../components/TableOfContents';
import RightSide from '../components/RightSide';
import { Code, Snippet } from '../components/CodeSnippet';
import ReferenceLink from '../components/ReferenceLink';
import PayLinkButton from '../components/PayLinkButton';
import AggregatorCredentialsAlert from '../components/AggregatorCredentialsAlert';

const elements: TableOfContentsElement[] = [
    {
        text: 'Introduction',
    },
    {
        text: 'How it works?',
    },
    {
        text: 'Pay Links',
    },
    {
        text: 'Checkout',
        size: '2',
    },
    {
        text: 'Bank Link',
        size: '2',
    },
    {
        text: 'Tipping',
        size: '2',
    },
];

const PayLink = () => {
    let anchor = 0;
    return (
        <SideBySideLayout>
            <LeftSide>
                <TableOfContents elements={elements} />
            </LeftSide>
            <RightSide>
                <h1>
                    Pay
                    <span className="text-primary">Link</span>
                </h1>
                <p className="lead my-4">Delegate user interactions to us</p>
                <p>Ideal when you don't want to spend time on the payment process.</p>

                <Anchor {...elements[anchor++]} />
                <p>
                    A Pay Link is a link that will take the customer to a proper page in order to execute a specific
                    action. Those actions can be the addition of a new payment method or a payment for example.
                </p>
                <p>
                    When using a Pay Link, UI integration is not required. Your customers can initiate payments without
                    needing to make changes to your original application.
                </p>
                <p>
                    You can find in the <ReferenceLink to="api_docs" route="api-reference-v2/paylinks/createpaylink" />{' '}
                    explanations how to call the Pay Link endpoints.
                </p>

                <Anchor {...elements[anchor++]} />
                <p>
                    We've built a specific web application to handle Pay Links. This application retrieves information
                    about the Pay Link and guides the customer through the payment steps.
                </p>
                <p>
                    A Pay Link is always generated for a specific customer. The response will provide a URL to redirect
                    the customer to. When implementing within your applicaiton, you will only need to pull the
                    information resulting from that redirection.
                </p>
                <AggregatorCredentialsAlert />

                <Anchor {...elements[anchor++]} />
                <p>
                    All the different Pay Links can be created using the same endpoint. Each type requires different
                    information for their creation.
                </p>

                <Anchor {...elements[anchor++]} />
                <p>
                    The <code>checkout</code> link will take the customer through a checkout flow. Details about the
                    cart can be provided in order to personalize this page.
                </p>
                <Snippet>
                    <Code
                        language="language-js"
                        code={`// ===================================
// Request`}
                    />
                    <Code
                        language="language-http"
                        code={`
POST /v2/links HTTP/1.1
Host: https://api.strongholdpay.com
SH-SECRET-KEY: sk_sandbox_sEGTb5Q9B8Pz-I5ZZ9dTKOko
Content-Type: application/json`}
                    />
                    <Code
                        language="language-json"
                        code={`
{
    "type": "checkout",
    "customer_id": "customer_-dr2n7sN5hGAuLlA2dtuNhR8",
    "order": {
        "total_amount": 5900,
        "tax_amount": 400,
        "items": [
        {
            "name": "Item 1",
            "description": "Description 1",
            "quantity": 2,
            "total_amount": 4000,
            "image_url": "https://cdnpub.strongholdpay.com/merchant-brands/bestrong/logo.png"
        },
        {
            "name": "Item 2",
            "description": "Description 2",
            "quantity": 3,
            "total_amount": 1500,
            "image_url": "https://cdnpub.strongholdpay.com/merchant-brands/bestrong/logo.png"
        }
        ]
    },
    "tip": {
        "beneficiary_name": "Joe"
    },
    "callbacks": {
        "success_url": "https://tutorials.strongholdpay.com/PayLink/success/",
        "exit_url": "https://tutorials.strongholdpay.com/PayLink/exit/"
    }
}
`}
                    />
                    <Code
                        language="language-json"
                        code={`
// ===================================
// Response
{
    "response_id": "resp_r7bpBll7QZ1a7IxkmcfONhXv",
    "time": "2020-09-29T13:13:25.832592Z",
    "status_code": 201,
    "result": {
        "id": "O5DzVkud",
        "type": "checkout",
        "expires_at": "2020-09-30T13:13:25.016132Z",
        "has_expired": false,
        "created_at": "2020-09-29T13:13:25.016129Z",
        "status": "created",
        "url": "https://development-sandbox.strongholdpay.com/l/O5DzVkud",
        "environment": "sandbox",
        "customer": {
            "id": "customer_-dr2n7sN5hGAuLlA2dtuNhR8",
            ...
        },
        "order": {
            "total_amount": 5900,
            "tax_amount": 400,
            "sub_amount": 5500,
            "convenience_fee": 0,
            "items": [
                {
                    "name": "Item 1",
                    "quantity": 2,
                    "total_amount": 4000,
                    "price": 2000,
                    "description": "Description 1",
                    "image_url": "https://cdnpub.strongholdpay.com/merchant-brands/bestrong/logo.png"
                },
                {
                    "name": "Item 2",
                    "quantity": 3,
                    "total_amount": 1500,
                    "price": 500,
                    "description": "Description 2",
                    "image_url": "https://cdnpub.strongholdpay.com/merchant-brands/bestrong/logo.png"
                }
            ],
        },
        "tip": {
            "beneficiary_name": "Joe"
        },
        "callbacks": {
            "success_url": "https://tutorials.strongholdpay.com/PayLink/success/",
            "exit_url": "https://tutorials.strongholdpay.com/PayLink/exit/"
        }
    }
}             
`}
                    />
                </Snippet>

                <PayLinkButton
                    id="pay-link-try-checkout-button"
                    configuration={{
                        type: 'checkout',
                        tipBeneficiaryName: 'John',
                    }}
                >
                    Try it
                </PayLinkButton>

                <Anchor {...elements[anchor++]} />
                <p>
                    The <code>bank_link</code> link allows a customer to add a new payment source. This can be used when
                    creating a charge or a tip.
                </p>
                <Snippet>
                    <Code
                        language="language-js"
                        code={`// ===================================
// Request`}
                    />
                    <Code
                        language="language-http"
                        code={`
POST /v2/links HTTP/1.1
Host: https://api.strongholdpay.com
SH-SECRET-KEY: sk_sandbox_sEGTb5Q9B8Pz-I5ZZ9dTKOko
Content-Type: application/json`}
                    />
                    <Code
                        language="language-json"
                        code={`
{
    "type": "bank_link",
    "customer_id": "customer_h.KlK0X8xtXQ1xbmfyJyyahQ",
    "callbacks": {
        "success_url": "https://tutorials.strongholdpay.com/PayLink/success/",
        "exit_url": "https://tutorials.strongholdpay.com/PayLink/exit/"
    }
}
`}
                    />
                    <Code
                        language="language-js"
                        code={`
// ===================================
// Response`}
                    />
                    <Code
                        language="language-json"
                        code={`
{
    "response_id": "resp_8fr-S59dF9bj9SdMS9WvY0DX",
    "time": "2020-06-22T14:51:54.821257Z",
    "status_code": 201,
    "result": {
        "id": "WGge7VDl",
        "type": "bank_link",
        "expires_at": "2020-06-23T16:44:47.192577+02:00",
        "has_expired": false,
        "created_at": "2020-06-22T16:44:47.174707+02:00",
        "status": "created",
        "url": "https://strongholdpay.com/l/WGge7VDl",
        "environment": "sandbox",
        "customer": {
            "id": "customer_h.KlK0X8xtXQ1xbmfyJyyahQ",
            ...
        },
        "callbacks": {
            "success_url": "https://tutorials.strongholdpay.com/PayLink/success/",
            "exit_url": "https://tutorials.strongholdpay.com/PayLink/exit/"
        }
    }
}
`}
                    />
                </Snippet>

                <PayLinkButton
                    id="pay-link-try-bank-link-button"
                    configuration={{
                        type: 'bank_link',
                    }}
                >
                    Try it
                </PayLinkButton>

                <Anchor {...elements[anchor++]} />
                <p>
                    The <code>tipping</code> link allows a customer to add a <strong>tip</strong> on top of a charge
                    previously authorized.
                </p>

                <Snippet>
                    <Code
                        language="language-js"
                        code={`// ===================================
// Request`}
                    />
                    <Code
                        language="language-http"
                        code={`
POST /v2/links HTTP/1.1
Host: https://api.strongholdpay.com
SH-SECRET-KEY: sk_sandbox_sEGTb5Q9B8Pz-I5ZZ9dTKOko
Content-Type: application/json`}
                    />
                    <Code
                        language="language-json"
                        code={`
{
    "type": "tipping",
    "customer_id": "customer_h.KlK0X8xtXQ1xbmfyJyyahQ",
    "charge_id": "charge_JbN2.-uFKjLexI0UYeNY6mWX",
    "tip": {
        "beneficiary_name": "Joe",
    },
    "callbacks": {
        "success_url": "https://tutorials.strongholdpay.com/PayLink/success/",
        "exit_url": "https://tutorials.strongholdpay.com/PayLink/exit/"
    }
}
`}
                    />
                    <Code
                        language="language-js"
                        code={`
// ===================================
// Response`}
                    />
                    <Code
                        language="language-json"
                        code={`
{
    "response_id": "resp_8fr-S59dF9bj9SdMS9WvY0DX",
    "time": "2020-06-22T14:51:54.821257Z",
    "status_code": 201,
    "result": {
        "id": "WGge7VDl",
        "type": "tipping",
        "expires_at": "2020-06-23T16:44:47.192577+02:00",
        "has_expired": false,
        "created_at": "2020-06-22T16:44:47.174707+02:00",
        "status": "created",
        "url": "https://strongholdpay.com/l/WGge7VDl",
        "environment": "sandbox",
        "customer": {
            "id": "customer_h.KlK0X8xtXQ1xbmfyJyyahQ",
            ...
        },
        "tip": {
            "beneficiary_name": "Joe"
        },
        "charge": {
            "id": "charge_JbN2.-uFKjLexI0UYeNY6mWX",
            ...
        },
        "callbacks": {
            "success_url": "https://tutorials.strongholdpay.com/PayLink/success/",
            "exit_url": "https://tutorials.strongholdpay.com/PayLink/exit/"
        }
    }
}`}
                    />
                </Snippet>

                <PayLinkButton
                    id="pay-link-try-tipping-button"
                    configuration={{
                        type: 'tipping',
                    }}
                >
                    Try it
                </PayLinkButton>
            </RightSide>
        </SideBySideLayout>
    );
};

export default connect()(PayLink);
