import React from 'react';

export class Snippet extends React.PureComponent<{ children: React.ReactNode }> {
    render() {
        return <pre className="my-5">{this.props.children}</pre>;
    }
}

export class Code extends React.PureComponent<{
    language?: 'language-js' | 'language-http' | 'language-json';
    code: string;
}> {
    componentDidMount() {
        (window as any).Prism.highlightAll();
    }

    render() {
        return <code className={this.props.language || 'language-js'}>{this.props.code}</code>;
    }
}
