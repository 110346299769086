// -----------------
// STATE - This defines the type of data maintained in the Redux store.

import { Reducer } from 'redux';
import { ApplicationAction, AppThunkAction } from './index';
import { setRequestingAction } from './request';

export interface CardSourceConfiguration {
    cardHolderName: string | null;
    tsepToken: string | null;
    expirationDate: string | null;
    cvv2: string | null;
    cardType: string | null;
    maskedCardNumber: string | null;
    zipCode: string | null;
}

export interface CardSourceState {
    configuration: CardSourceConfiguration;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetCardSourceConfigurationAction {
    type: 'SET_CARD_SOURCE_CONFIGURATION';
    payload: CardSourceConfiguration;
}
export const setCardSourceConfiguration = (data: CardSourceConfiguration): SetCardSourceConfigurationAction => {
    return {
        type: 'SET_CARD_SOURCE_CONFIGURATION',
        payload: data,
    };
};

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetCardSourceConfigurationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setCardSourceConfiguration: (data: CardSourceConfiguration): AppThunkAction<ApplicationAction> => async (
        dispatch,
        getState,
    ) => {
        dispatch(setRequestingAction('add_card_source'));
        dispatch(setCardSourceConfiguration(data));
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CardSourceState = {
    configuration: {
        cardHolderName: '',
        tsepToken: '',
        expirationDate: '',
        cvv2: '',
        cardType: '',
        maskedCardNumber: '',
        zipCode: '',
    },
};

export const reducer: Reducer<CardSourceState, KnownAction> = (
    state: CardSourceState = unloadedState,
    action: KnownAction,
): CardSourceState => {
    switch (action.type) {
        case 'SET_CARD_SOURCE_CONFIGURATION':
            const expirationDate = action.payload.expirationDate!.replace('/', '');
            const cvv2 = action.payload.cvv2!.trim();
            return {
                ...state,
                configuration: { ...action.payload, expirationDate, cvv2 },
            };
        default:
            return state;
    }
};
