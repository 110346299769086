import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { Button } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState, actionCreators } from '../../store';
import Loader from '../Loader';

interface StateProps {
    requesting: boolean;
    disabled: boolean;
}

interface OwnProps {
    id?: string;
    className?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    dataSh?: string;
    amount: number;
    cardHolderName: string;
    token: string;
    expirationDate: string;
    cvv2: string;
    cardType: string;
    maskedCardNum: string;
    zipCode: string;
    onSubmit: () => void;
}

interface State {
    isReady: boolean;
}

interface DispatchProps {
    addCardCharge: typeof actionCreators.transaction.addCardCharge;
}

type Props = StateProps & OwnProps & DispatchProps;

class AddCardChargeButton extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isReady: false,
        };
    }

    onClick = async () => {
        this.setState({ isReady: true });
    };

    componentDidUpdate = async (prevProps: Readonly<Props>) => {
        if (this.state.isReady && prevProps.token !== this.props.token && this.props.token !== '') {
            try {
                await this.props.addCardCharge({
                    amount: this.props.amount,
                    token: this.props.token,
                    expirationDate: this.props.expirationDate,
                    cvv2: this.props.cvv2,
                    cardType: this.props.cardType,
                    maskedCardNum: this.props.maskedCardNum,
                    cardHolderName: this.props.cardHolderName,
                    zipCode: this.props.zipCode,
                });
                this.props.onSubmit();
            } catch {
                // handles in toast
            }
            this.setState({ isReady: false });
        }
    };

    render() {
        return (
            <Button
                id={this.props.id}
                color="primary"
                disabled={this.props.disabled || this.state.isReady}
                data-sh={this.props.dataSh || this.props.id || 'charge-button'}
                className={['d-flex align-items-center', this.props.className || ''].join(' ')}
                onClick={this.onClick}
            >
                <span>{this.props.children || 'Create a Charge'}</span>
                {this.props.requesting || this.state.isReady ? <Loader className="ml-2" /> : null}
            </Button>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (
    state: ApplicationState,
    ownProps: OwnProps,
) => {
    const requesting = state.request.requesting === 'add_charge';

    return {
        disabled: requesting || ownProps.disabled === true,
        requesting,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators.transaction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddCardChargeButton);
