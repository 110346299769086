import * as configuration from './configuration';
import * as customer from './customer';
import * as request from './request';
import * as paymentSource from './paymentSource';
import * as transaction from './transaction';
import * as payLink from './payLink';
import * as cardSource from './cardSource';

// The top-level state object
export interface ApplicationState {
    configuration: configuration.ConfigurationState;
    customer: customer.CustomerState;
    request: request.RequestState;
    paymentSource: paymentSource.PaymentSourceState;
    transaction: transaction.TransactionState;
    payLink: payLink.PayLinkState;
    cardSource: cardSource.CardSourceState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    configuration: configuration.reducer,
    customer: customer.reducer,
    request: request.reducer,
    paymentSource: paymentSource.reducer,
    transaction: transaction.reducer,
    payLink: payLink.reducer,
    cardSource: cardSource.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export const actionCreators = {
    configuration: configuration.actionCreators,
    customer: customer.actionCreators,
    request: request.actionCreators,
    paymentSource: paymentSource.actionCreators,
    transaction: transaction.actionCreators,
    payLink: payLink.actionCreators,
    cardSource: cardSource.actionCreators,
};

export type ApplicationAction =
    | configuration.KnownAction
    | customer.KnownAction
    | request.KnownAction
    | paymentSource.KnownAction
    | transaction.KnownAction
    | payLink.KnownAction
    | cardSource.KnownAction;
