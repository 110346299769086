import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import React from 'react';
import url from 'url';
import querystring from 'querystring';

const PayLinkCallback = (props: RouteComponentProps) => {
    const search = querystring.parse(url.parse(document.location.href).query || '') as {
        pay_link_callback: 'success' | 'exit';
        pay_link_id: string;
        pay_link_type: string;
        payment_source_id?: string;
        charge_id?: string;
        tip_id?: string;
        custom_key?: string;
    };

    return (
        <div className="h-100 bg-light py-3">
            <div className="container">
                <div className="card mw-md mx-auto">
                    <div className="card-body p-md-5">
                        <div className="h1" data-sh="title">
                            PayLink {search.pay_link_callback === 'success' ? 'Succeeded' : 'Exited'}
                        </div>
                        <p>
                            The <code>{search.pay_link_callback}</code> callback url of the PayLink with the code{' '}
                            <strong>{search.pay_link_id}</strong> has been called.
                        </p>
                        <p>Here is some complementary information about it.</p>
                        <table className="table mb-3">
                            <tbody>
                                <tr>
                                    <td>Id</td>
                                    <td data-sh="pay-link-id" className="font-weight-bold text-right">
                                        {search.pay_link_id}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Type</td>
                                    <td data-sh="pay-link-type" className="font-weight-bold text-right">
                                        {search.pay_link_type}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Output</td>
                                    <td data-sh="pay-link-callback" className="font-weight-bold text-right">
                                        {search.pay_link_callback}
                                    </td>
                                </tr>
                                {search.payment_source_id ? (
                                    <tr>
                                        <td>Payment Source</td>
                                        <td data-sh="payment-source-id" className="text-right">
                                            <code>{search.payment_source_id}</code>
                                        </td>
                                    </tr>
                                ) : null}
                                {search.charge_id ? (
                                    <tr>
                                        <td>Charge</td>
                                        <td data-sh="charge-id" className="text-right">
                                            <code>{search.charge_id}</code>
                                        </td>
                                    </tr>
                                ) : null}
                                {search.tip_id ? (
                                    <tr>
                                        <td>Tip</td>
                                        <td data-sh="tip-id" className="text-right">
                                            <code>{search.tip_id}</code>
                                        </td>
                                    </tr>
                                ) : null}
                                {search.custom_key ? (
                                    <tr>
                                        <td>
                                            <div>Custom Key</div>
                                            <small className="text-muted">
                                                You can add you own query parameters. They will be prefixed with{' '}
                                                <code>custom_</code> in the callback query parameters.
                                            </small>
                                        </td>
                                        <td data-sh="pay-link-custom" className="font-weight-bold text-right">
                                            My Custom Value
                                        </td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </table>
                        <div className="text-right">
                            <Link className="btn btn-primary" to="/payLink">
                                Return to the PayLink Page
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(PayLinkCallback);
