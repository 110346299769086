import { snakeCase } from 'snake-case';
import { HashLink } from 'react-router-hash-link';
import React from 'react';

export interface TableOfContentsElement {
    text: string;
    anchor?: string;
    size?: '1' | '2' | '3';
}

export interface TableOfContentsProps {
    elements: Array<TableOfContentsElement>;
}

export const TableOfContents = (props: TableOfContentsProps) => (
    <ul className="nav flex-column">
        {props.elements.map((el, key) => {
            const classes = ['nav-link', 'text-dark'];

            if (el.size === '2') {
                classes.push('py-1');
            } else if (el.size === '3') {
                classes.push('py-1');
            } else {
                classes.push('h5 mb-0');
            }

            return (
                <li key={key} className="nav-item">
                    <HashLink className={classes.join(' ')} to={`#${el.anchor || snakeCase(el.text)}`}>
                        {el.text}
                    </HashLink>
                </li>
            );
        })}
    </ul>
);

export interface AnchorProps extends TableOfContentsElement {}

export const Anchor = (props: AnchorProps) => {
    const className = ['mt-5 mb-4'].join(' ');
    const id = props.anchor || snakeCase(props.text);

    if (props.size === '2') {
        return (
            <h3 id={id} className={className}>
                {props.text}
            </h3>
        );
    } else if (props.size === '3') {
        return (
            <h4 id={id} className={className}>
                {props.text}
            </h4>
        );
    } else {
        return (
            <h2 id={id} className={className}>
                {props.text}
            </h2>
        );
    }
};
