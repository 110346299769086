import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React from 'react';

const Home = () => (
    <div>
        <div className="jumbotron jumbotron-fluid bg-light">
            <div className="container">
                <div>
                    <span className="display-2 text-primary mr-3">Tutorials</span>
                    <small className="display-4">for Stronghold Pay</small>
                </div>
                <div className="lead my-4">
                    Quickly get a project started using our tutorials. Human friendly and straightforward.
                </div>
                <div>
                    Here you can find demonstrations to help users quickly get into Stronghold Pay features. The JS
                    library, Pay Links and demos are implemented to give you an idea on how to integrate our solution
                    with your product.
                </div>
            </div>
        </div>
        <div className="container my-5 bg-white">
            <div className="row">
                <div className="col-md-4 text-center">
                    <div className="h-100 d-flex flex-column justify-content-between p-3 px-lg-4">
                        <div>
                            <h4>Web SDK demos</h4>
                            <p className="lead">Integrating our Javascript library</p>
                            <p>
                                Providing functionality that allows you to add payment processing to your web
                                Application.
                            </p>
                        </div>
                        <div>
                            <Link className="btn btn-primary" href="" role="button" to="/sdk">
                                Learn more
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 text-center">
                    <div className="h-100 d-flex flex-column justify-content-between p-3 px-lg-4">
                        <div>
                            <h4>Pay Links</h4>
                            <p className="lead">Delegate user interactions to us</p>
                            <p>Ideal when you don't want to spend time on the payment process.</p>
                        </div>
                        <div>
                            <Link className="btn btn-primary" role="button" to="/paylink">
                                Learn more
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 text-center">
                    <div className="h-100 d-flex flex-column justify-content-between p-3 px-lg-4">
                        <div>
                            <h4>Use Cases</h4>
                            <p className="lead">A cart summary demonstration</p>
                            <p>A simple checkout flow showing how to integrate our JS library.</p>
                        </div>
                        <div>
                            <Link className="btn btn-primary" role="button" to="/use-cases">
                                Learn more
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default connect()(Home);
