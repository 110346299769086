// -----------------
// STATE - This defines the type of data maintained in the Redux store.

import { Reducer } from 'redux';
import { AppThunkAction } from './index';
import client, { AggregatorConfiguration, AggregatorName, Configuration } from '../client';
import url from 'url';
import * as querystring from 'querystring';

export interface ConfigurationState {
    config: Configuration;
    aggregator: AggregatorConfiguration | null;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetConfigurationAction {
    type: 'SET_CONFIGURATION';
    payload: Configuration;
}
export const setConfiguration = (config: Configuration): SetConfigurationAction => ({
    type: 'SET_CONFIGURATION',
    payload: config,
});

export interface SetAggregatorConfigAction {
    type: 'SET_AGGREGATOR_CONFIGURATION';
    payload: AggregatorName;
}
export const setAggregatorConfiguration = (aggregatorName: AggregatorName): SetAggregatorConfigAction => ({
    type: 'SET_AGGREGATOR_CONFIGURATION',
    payload: aggregatorName,
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetConfigurationAction | SetAggregatorConfigAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    fetchConfiguration: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const configuration = await client.getConfiguration();
        dispatch(setConfiguration(configuration));

        const search = querystring.parse(url.parse(document.location.href).query || '');
        const aggregator = search.aggregator as AggregatorName;
        dispatch(setAggregatorConfiguration(aggregator));
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ConfigurationState = {
    config: { apiHost: '', tsepScriptPath: '', showCardProcessing: '', showTsepLogging: '', aggregators: [] },
    aggregator: null,
};

export const reducer: Reducer<ConfigurationState, KnownAction> = (
    state: ConfigurationState = unloadedState,
    action: KnownAction,
): ConfigurationState => {
    switch (action.type) {
        case 'SET_CONFIGURATION':
            return {
                ...state,
                config: action.payload,
                aggregator: action.payload.aggregators[0] || null,
            };
        case 'SET_AGGREGATOR_CONFIGURATION':
            const configFound = state.config.aggregators.find((el) => el.name === action.payload) || state.aggregator;
            return { ...state, aggregator: configFound };
        default:
            return state;
    }
};
