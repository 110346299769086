// -----------------
// STATE - This defines the type of data maintained in the Redux store.

import { Reducer } from 'redux';
import { ApplicationAction, AppThunkAction } from './index';
import { setRequestingAction } from './request';
import client from '../client';
import { history } from '../index';
import url from 'url';
import * as querystring from 'querystring';

type PayLinkType = 'checkout' | 'bank_link' | 'tipping';

export interface PayLinkConfiguration {
    type: PayLinkType;
    withCallbacks: boolean | null;
    treez: boolean | null;
    tipBeneficiaryName: string | null;
    tipDetailsDisplayMessage: string | null;
}

export interface PayLinkState {
    configuration: PayLinkConfiguration;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetPayLinkConfigurationAction {
    type: 'SET_PAY_LINK_CONFIGURATION';
    payload: PayLinkConfiguration;
}
export const setPayLinkConfiguration = (payload: querystring.ParsedUrlQuery = {}): SetPayLinkConfigurationAction => {
    return {
        type: 'SET_PAY_LINK_CONFIGURATION',
        payload: {
            tipBeneficiaryName:
                typeof payload.tipBeneficiaryName === 'string' ? payload.tipBeneficiaryName || null : null,
            tipDetailsDisplayMessage:
                typeof payload.tipDetailsDisplayMessage === 'string' ? payload.tipDetailsDisplayMessage || null : null,
            treez: payload.treez === 'true',
            type: (payload.type as PayLinkType) || 'checkout',
            withCallbacks: payload.withCallbacks === 'false' ? false : true,
        },
    };
};

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetPayLinkConfigurationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    tryPayLink: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const search = querystring.parse(url.parse(document.location.href).query || '');
        dispatch(setPayLinkConfiguration(search));

        try {
            const payLink = await client.createPayLink(getState().payLink.configuration);
            window.location.href = payLink.url;
        } catch (e) {
            history.push('/paylink');
            throw e;
        }
    },

    createPayLink: (configuration: Partial<PayLinkConfiguration> = {}): AppThunkAction<ApplicationAction> => async (
        dispatch,
        getState,
    ) => {
        const merged = {
            ...getState().payLink.configuration,
            ...configuration,
        };
        dispatch(setRequestingAction('create_pay_link'));
        try {
            const payLink = await client.createPayLink(merged);
            window.open(payLink.url, '_blank');
        } finally {
            dispatch(setRequestingAction());
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PayLinkState = {
    configuration: {
        type: 'checkout',
        tipBeneficiaryName: null,
        withCallbacks: null,
        treez: null,
        tipDetailsDisplayMessage: null,
    },
};

export const reducer: Reducer<PayLinkState, KnownAction> = (
    state: PayLinkState = unloadedState,
    action: KnownAction,
): PayLinkState => {
    switch (action.type) {
        case 'SET_PAY_LINK_CONFIGURATION':
            console.log(action.payload);
            return {
                ...state,
                configuration: action.payload,
            };
        default:
            return state;
    }
};
