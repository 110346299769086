import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { Button } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState, actionCreators } from '../../store';
import Loader from '../Loader';

interface StateProps {
    requesting: boolean;
    disabled: boolean;
}

interface OwnProps {
    id?: string;
    className?: string;
    children?: React.ReactNode;
    disabled?: boolean;
}

interface DispatchProps {
    addPaymentSource: typeof actionCreators.paymentSource.addPaymentSource;
}

type Props = StateProps & OwnProps & DispatchProps;

class AddPaymentSourceButton extends React.PureComponent<Props> {
    onClick = async () => {
        this.props.addPaymentSource();
    };
    render() {
        return (
            <Button
                id={this.props.id}
                color="primary"
                disabled={this.props.disabled}
                data-sh="add-payment-source-button"
                className={['d-flex align-items-center', this.props.className || ''].join(' ')}
                onClick={this.onClick}
            >
                <span>{this.props.children || 'Add Payment Source'}</span>
                {this.props.requesting ? <Loader className="ml-2" /> : null}
            </Button>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (
    state: ApplicationState,
    ownProps: OwnProps,
) => {
    const apiHost = state.configuration.config.apiHost;
    const publishableKey = state.configuration.aggregator?.publishableKey || null;
    const requesting = state.request.requesting === 'add_payment_source';

    return {
        disabled: !apiHost || !publishableKey || requesting || ownProps.disabled === true,
        requesting,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators.paymentSource, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentSourceButton);
