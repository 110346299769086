import { connect, MapStateToPropsParam } from 'react-redux';
import React from 'react';
import { AggregatorName } from '../client';
import { ApplicationState } from '../store';
import { Alert } from 'reactstrap';

interface StateProps {
    aggregator: AggregatorName;
}

type Props = StateProps;

const AggregatorCredentialsAlert = (props: Props) => {
    let username = 'user_good';
    let password = 'pass_good';

    if (props.aggregator === 'yodlee') {
        username = 'YodTest.site16441.2';
        password = 'site16441.2';
    }

    return (
        <Alert color="info">
            <p>
                You can test a successful <code>payment_source</code> link in the{' '}
                <span className="font-weight-bold">Sandbox</span> environment by logging into any banking institution
                with these test credentials:
            </p>
            <div>
                Username: <code>{username}</code>
            </div>
            <div>
                Password: &nbsp;<code>{password}</code>
            </div>
        </Alert>
    );
};

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state: ApplicationState) => ({
    aggregator: state.configuration.aggregator?.name || 'plaid',
});

export default connect(mapStateToProps)(AggregatorCredentialsAlert);
