import { gql } from 'graphql-request';

export const configurationQuery = gql`
    {
        configuration {
            apiHost
            tsepScriptPath
            showCardProcessing
            showTsepLogging
            aggregators {
                name
                publishableKey
            }
        }
    }
`;

export const getCustomerTokenQuery = gql`
    query($id: String) {
        customer(id: $id) {
            id
            token
        }
    }
`;

export const resetPaymentSourceMutation = gql`
    mutation($id: String!, $aggregator: Aggregator!) {
        resetPaymentSource(id: $id, aggregator: $aggregator)
    }
`;

export const createSandboxPaymentSourceMutation = gql`
    mutation($customerId: String!) {
        createSandboxPaymentSource(customerId: $customerId) {
            id
            label
        }
    }
`;

export const createChargeMutation = gql`
    mutation($customerId: String!, $paymentSourceId: String!) {
        createCharge(customerId: $customerId, paymentSourceId: $paymentSourceId) {
            id
            amount
        }
    }
`;

export const createCardChargeMutation = gql`
    mutation(
        $amount: Int!
        $token: String!
        $expirationDate: String!
        $cvv2: String!
        $cardType: String!
        $cardHolderName: String!
        $zipCode: String!
    ) {
        createCardCharge(
            amount: $amount
            token: $token
            expirationDate: $expirationDate
            cvv2: $cvv2
            cardType: $cardType
            cardHolderName: $cardHolderName
            zipCode: $zipCode
        )
    }
`;

export const generateTsepManifest = gql`
    mutation {
        generateTsepManifest
    }
`;

export const createPayLinkMutation = gql`
    mutation(
        $type: PayLinkType!
        $tipBeneficiaryName: String
        $tipDetailsDisplayMessage: String
        $withCallbacks: Boolean
        $treez: Boolean
    ) {
        createPayLink(
            type: $type
            withCallbacks: $withCallbacks
            treez: $treez
            tipBeneficiaryName: $tipBeneficiaryName
            tipDetailsDisplayMessage: $tipDetailsDisplayMessage
        ) {
            id
            url
        }
    }
`;
