import { connect, MapStateToPropsParam } from 'react-redux';
import React from 'react';
import { Link } from 'react-router-dom';
import AddPaymentSourceButton from '../components/sdk/AddPaymentSourceButton';
import AddChargeButton from '../components/sdk/AddChargeButton';
import { actionCreators, ApplicationState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import AggregatorCredentialsAlert from '../components/AggregatorCredentialsAlert';
import { CardSourceConfiguration, setCardSourceConfiguration } from '../store/cardSource';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import AddCardChargeButton from '../components/sdk/AddCardChargeButton';
import { Helmet } from 'react-helmet';

interface StateProps {
    paymentSourceId: string | null;
    paymentSourceLabel: string | null;
    chargeId: string | null;
    showCardProcessing: string | null;
    showTsepLogging: string | null;
    cardHolderName: string | null;
    tsepToken: string | null;
    maskedCardNumber: string | null;
    expirationDate: string | null;
    cvv2: string | null;
    cardType: string | null;
    zipCode: string | null;
    tsepScriptPath: string;
}

interface DispatchProps {
    setCardSourceConfiguration: typeof setCardSourceConfiguration;
    addCardCharge: typeof actionCreators.transaction.addCardCharge;
    generateTsepManifest: typeof actionCreators.transaction.generateTsepManifest;
}

type Props = StateProps & DispatchProps;

interface State {
    paid: boolean;
    activeTab: number;
    cardAuth: boolean;
    tsepManifest: string;
}

enum TabName {
    'PAY_BY_ACH' = 1,
    'PAY_BY_CARD' = 2,
}

class UseCases extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            paid: false,
            activeTab: TabName.PAY_BY_ACH,
            cardAuth: false,
            tsepManifest: '',
        };
    }

    componentDidMount = async () => {
        try {
            const manifest = await this.props.generateTsepManifest();
            const obj = JSON.parse(JSON.stringify(manifest));
            this.setState({ tsepManifest: obj.generateTsepManifest });
        } catch (e) {
            console.log(e);
        }
        (window as any).tsepHandler = (eventType: any, event: any) => {
            if (this.props.showTsepLogging === 'Y') {
                console.log('Event Type: ' + eventType);
                console.log('Event:');
                console.log(event);
            }
            if (eventType === 'TokenEvent' && event.responseCode === 'A0000') {
                const result = {
                    cardHolderName: event.cardHolderName,
                    tsepToken: event.tsepToken,
                    expirationDate: event.expirationDate,
                    cvv2: event.cvv2,
                    cardType: event.cardType,
                    maskedCardNumber: event.maskedCardNumber,
                    zipCode: event.zipCode,
                } as CardSourceConfiguration;
                this.props.setCardSourceConfiguration(result);
            }
        };
    };

    componentDidUpdate(prev: Props) {
        if (this.props.chargeId && this.props.chargeId !== prev.chargeId) {
            this.setState({ paid: true });
        }
    }

    setPaidTrue = () => {
        this.setState({ paid: true });
    };

    render() {
        const props = this.props;
        return (
            <div>
                <Helmet>
                    <script src={`${this.props.tsepScriptPath}${this.state.tsepManifest}`}></script>
                    <script>function tsepHandler(eventType, event){}</script>
                </Helmet>
                <div>
                    <div className="jumbotron jumbotron-fluid mb-3 bg-light">
                        <div className="container">
                            <div className="display-3 mb-3">Cart Summary</div>
                            <div className="lead mb-3">This is an example of a checkout page.</div>
                            <div className="lead">
                                Here the customer reviews their order and the steps required to complete the payment.
                            </div>
                            <p>
                                It showcases the different <strong>Stronghold Pay</strong> dropin features.
                            </p>
                            <Link className="btn btn-primary" to="/">
                                Learn more
                            </Link>
                        </div>
                    </div>
                    <div className="container my-5">
                        <section id="checkout" className="row">
                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-header">Items</div>
                                    <div className="card-body">
                                        <div className="media">
                                            <img
                                                src="/images/leaf1.png"
                                                className="mr-3"
                                                alt="Item 1"
                                                width="72"
                                                height="72"
                                            />
                                            <div className="media-body">
                                                <h5 className="mb-1">Monstera Split Leaf</h5>
                                                <div className="mb-1">"22" stem to fit planters and vases.</div>
                                                <div className="d-flex justify-content-between">
                                                    <small className="text-muted">x 2</small>
                                                    <strong className="">$34.50</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <img
                                                src="/images/leaf2.png"
                                                className="mr-3"
                                                alt="Item 1"
                                                width="72"
                                                height="72"
                                            />
                                            <div className="media-body">
                                                <h5 className="mb-1">Palm Leaf</h5>
                                                <div className="mb-1">
                                                    "36" stem perfect for creating unique bouquets.
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <small className="text-muted">x 1</small>
                                                    <strong className="">$10.75</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">Payment Method</div>
                                    <div className="card-body">
                                        {this.props.showCardProcessing === 'Y' ? (
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active: this.state.activeTab === TabName.PAY_BY_ACH,
                                                        })}
                                                        onClick={() => {
                                                            if (this.state.activeTab !== TabName.PAY_BY_ACH)
                                                                this.setState({ activeTab: TabName.PAY_BY_ACH });
                                                        }}
                                                    >
                                                        Pay by ACH
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active: this.state.activeTab === TabName.PAY_BY_CARD,
                                                        })}
                                                        onClick={() => {
                                                            if (this.state.activeTab !== TabName.PAY_BY_CARD)
                                                                this.setState({ activeTab: TabName.PAY_BY_CARD });
                                                        }}
                                                    >
                                                        Pay by Card
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        ) : (
                                            <></>
                                        )}
                                        {this.props.showCardProcessing === 'Y' ? (
                                            <TabContent activeTab={this.state.activeTab}>
                                                <TabPane tabId={TabName.PAY_BY_ACH}>
                                                    <div className="card-body">
                                                        <div id="payment-source">
                                                            {props.paymentSourceLabel ? (
                                                                <ul
                                                                    id="payment-source-list"
                                                                    className="list-group mb-3"
                                                                >
                                                                    <li className="list-group-item">
                                                                        <div className="form-check-inline">
                                                                            <input
                                                                                id="payment_source_.34HYlangXkSgNHFJIhXHBth_option"
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="payment_source"
                                                                                value="1"
                                                                                checked
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={props.paymentSourceLabel}
                                                                            >
                                                                                {props.paymentSourceLabel}
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            ) : (
                                                                <div id="payment-source-none" className="mb-3">
                                                                    In order to complete a payment, you must securely
                                                                    link a bank account.
                                                                </div>
                                                            )}
                                                            <AggregatorCredentialsAlert />
                                                            <div className="float-right">
                                                                <AddPaymentSourceButton
                                                                    id="add-payment-source-button"
                                                                    data-sh="add-payment-source-button"
                                                                    disabled={this.state.paid}
                                                                >
                                                                    Link a Bank Account
                                                                </AddPaymentSourceButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={TabName.PAY_BY_CARD}>
                                                    <div className="card-body">
                                                        <div id="payment-source">
                                                            {props.tsepToken && this.state.cardAuth ? (
                                                                <ul
                                                                    id="payment-source-list"
                                                                    className="list-group mb-3"
                                                                >
                                                                    <li className="list-group-item">
                                                                        <div className="form-check-inline">
                                                                            <input
                                                                                id="payment_source_.34HYlangXkSgNHFJIhXHBth_option"
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="payment_source"
                                                                                value="1"
                                                                                checked
                                                                                readOnly
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={props.tsepToken}
                                                                            >
                                                                                {`Card ending in ${props.maskedCardNumber}`}
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            ) : (
                                                                <div id="payment-source-none" className="mb-3">
                                                                    In order to complete a payment by card, you must
                                                                    input valid card details.
                                                                </div>
                                                            )}
                                                            <p>Card Information</p>
                                                            <div className="cardDetails">
                                                                <form method="POST" id="payment-form">
                                                                    <div
                                                                        className="tsep-cardHolderName"
                                                                        id="tsep-cardHolderNameDiv"
                                                                        data-validate-name="Y"
                                                                    ></div>
                                                                    <div
                                                                        className="tsep-cardNum"
                                                                        id="tsep-cardNumDiv"
                                                                        data-auto-formatting="Y"
                                                                        data-validate-cc="Y"
                                                                        data-detect-card-type="Y"
                                                                    ></div>
                                                                    <div
                                                                        className="tsep-datePicker"
                                                                        id="tsep-datepickerDiv"
                                                                        data-validate-expiry-date="Y"
                                                                    ></div>
                                                                    <div
                                                                        className="tsep-cvv2"
                                                                        id="tsep-cvv2Div"
                                                                        data-validate-cvv2="Y"
                                                                    ></div>
                                                                    <div
                                                                        className="tsep-zip"
                                                                        id="tsep-zipCodeDiv"
                                                                        data-validate-zipcode="Y"
                                                                    ></div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        ) : (
                                            <div id="payment-source">
                                                {props.paymentSourceLabel ? (
                                                    <ul id="payment-source-list" className="list-group mb-3">
                                                        <li className="list-group-item">
                                                            <div className="form-check-inline">
                                                                <input
                                                                    id="payment_source_.34HYlangXkSgNHFJIhXHBth_option"
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="payment_source"
                                                                    value="1"
                                                                    checked
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={props.paymentSourceLabel}
                                                                >
                                                                    {props.paymentSourceLabel}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                ) : (
                                                    <div id="payment-source-none" className="mb-3">
                                                        In order to complete a payment, you must securely link a bank
                                                        account.
                                                    </div>
                                                )}
                                                <AggregatorCredentialsAlert />
                                                <div className="float-right">
                                                    <AddPaymentSourceButton
                                                        id="add-payment-source-button"
                                                        data-sh="add-payment-source-button"
                                                        disabled={this.state.paid}
                                                    >
                                                        Link a Bank Account
                                                    </AddPaymentSourceButton>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <img
                                            className="d-block mx-auto mb-4"
                                            src="https://cdnpub.strongholdpay.com/merchant-brands/bestrong/logo.png"
                                            alt=""
                                            width="72"
                                            height="72"
                                        />
                                        <div>845 Market Street</div>
                                        <div>San Francisco, California 94105</div>
                                        <div>United States</div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <small className="d-flex justify-content-between text-muted">
                                            <div>Subtotal</div>
                                            <div>$45.25</div>
                                        </small>
                                        <small className="d-flex justify-content-between text-muted">
                                            <div>Tax</div>
                                            <div>$4.52</div>
                                        </small>
                                        <small className="d-flex justify-content-between text-muted">
                                            <div>Convenience Fee</div>
                                            <div>$3.00</div>
                                        </small>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="h5 text-primary mb-0">Total</div>
                                            <div className="h3 text-primary font-weight-bolder mb-0">$52.77</div>
                                        </div>
                                        <hr />
                                        {this.props.showCardProcessing === 'Y' &&
                                        this.state.activeTab === TabName.PAY_BY_CARD ? (
                                            <AddCardChargeButton
                                                id="demo-checkout-pay-button"
                                                dataSh="charge-button"
                                                className="btn-block btn-lg justify-content-center"
                                                disabled={this.state.paid}
                                                amount={5277}
                                                cardHolderName={this.props.cardHolderName!}
                                                token={this.props.tsepToken!}
                                                expirationDate={this.props.expirationDate!}
                                                cvv2={this.props.cvv2!}
                                                cardType={this.props.cardType!}
                                                maskedCardNum={this.props.maskedCardNumber!}
                                                zipCode={this.props.zipCode!}
                                                onSubmit={() => this.setPaidTrue()}
                                            >
                                                {this.state.paid ? 'PAID' : 'Pay $52.77'}
                                            </AddCardChargeButton>
                                        ) : (
                                            <AddChargeButton
                                                id="demo-checkout-pay-button"
                                                dataSh="charge-button"
                                                className="btn-block btn-lg justify-content-center"
                                                paymentSourceId={props.paymentSourceId || undefined}
                                                disabled={
                                                    (!props.paymentSourceLabel &&
                                                        (!this.props.tsepToken || !this.state.cardAuth)) ||
                                                    this.state.paid
                                                }
                                                chargeAmount={5277}
                                            >
                                                {this.state.paid ? 'PAID' : 'Pay $52.77'}
                                            </AddChargeButton>
                                        )}
                                        {this.props.showCardProcessing === 'Y' &&
                                        this.state.activeTab === TabName.PAY_BY_CARD ? (
                                            <div className="text-center mt-1">
                                                By clicking Pay above, you authorize <strong>be strong.</strong> to
                                                electronically charge your card for <strong>$52.77</strong>.
                                            </div>
                                        ) : (
                                            <div className="text-center mt-1">
                                                By clicking Pay above, you authorize <strong>be strong.</strong> to
                                                electronically debit your{' '}
                                                <strong data-sh="payment-source-label">
                                                    {props.paymentSourceLabel || 'Bank Account'}
                                                </strong>{' '}
                                                for <strong>$52.77</strong>.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state) => ({
    paymentSourceId: state.paymentSource.paymentSource?.id || null,
    paymentSourceLabel: state.paymentSource.paymentSource?.label || null,
    chargeId: state.transaction.charge?.id || null,
    showCardProcessing: state.configuration.config.showCardProcessing,
    showTsepLogging: state.configuration.config.showTsepLogging,
    cardHolderName: state.cardSource.configuration.cardHolderName,
    tsepToken: state.cardSource.configuration.tsepToken,
    maskedCardNumber: state.cardSource.configuration.maskedCardNumber,
    expirationDate: state.cardSource.configuration.expirationDate,
    cvv2: state.cardSource.configuration.cvv2,
    cardType: state.cardSource.configuration.cardType,
    zipCode: state.cardSource.configuration.zipCode,
    tsepScriptPath: state.configuration.config.tsepScriptPath,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            ...actionCreators.transaction,
            setCardSourceConfiguration,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(UseCases);
