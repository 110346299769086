import { ApplicationState, reducers } from './';
import { History } from 'history';
import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';

export default function configureStore<T>(history: History<T>, initialState?: ApplicationState) {
    const middleware = [thunk, routerMiddleware(history)];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    const enhancers: any[] = [];
    const windowIfDefined = typeof window === 'undefined' ? null : (window as any); // eslint-disable-line @typescript-eslint/no-explicit-any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
