import { connect } from 'react-redux';
import ReferenceLink from './ReferenceLink';
import React from 'react';

const Footer = () => (
    <footer className="bg-dark text-white py-5">
        <div className="container">
            <div className="row mb-5">
                <div className="col">
                    <img
                        src="/images/stronghold-logo-type-white.svg"
                        className="img-responsive"
                        alt="Stronghold Pay"
                        style={{ height: 40 }}
                    />
                </div>
                <div className="col text-right d-flex flex-column align-items-end justify-content-center">
                    <ReferenceLink className="my-1 text-white" to={'marketing'} />
                    <ReferenceLink className="my-1 text-white" to={'api_docs'} />
                </div>
            </div>
            <div className="text-left py-3">© Stronghold 2017 - {new Date().getFullYear()}. All rights reserved.</div>
        </div>
    </footer>
);

export default connect()(Footer);
