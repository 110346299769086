import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState, actionCreators } from '../store';
import { PayLinkConfiguration } from '../store/payLink';

interface StateProps {
    requesting: boolean;
    disabled: boolean;
}

interface OwnProps {
    id?: string;
    className?: string;
    children?: React.ReactNode;
    configuration?: Partial<PayLinkConfiguration>;
}

interface DispatchProps {
    createPayLink: typeof actionCreators.payLink.createPayLink;
}

type Props = StateProps & OwnProps & DispatchProps;

class PayLinkButton extends React.PureComponent<Props> {
    onClick = async () => {
        this.props.createPayLink(this.props.configuration);
    };

    render() {
        return (
            <Button
                id={this.props.id}
                color="primary"
                disabled={this.props.disabled}
                data-sh="add-payment-source-button"
                className={['d-flex align-items-center', this.props.className || ''].join(' ')}
                onClick={this.onClick}
            >
                <span>{this.props.children || 'Try Pay Link'}</span>
                {this.props.requesting ? <Spinner color="white" className="ml-2" size="sm" /> : null}
            </Button>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => ({
    disabled: !!state.request.requesting,
    requesting: state.request.requesting === 'create_pay_link',
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators.payLink, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayLinkButton);
