import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../store';
import { actionCreators } from '../store';
import { Helmet } from 'react-helmet';

interface StateProps {
    apiHost: string | null;
}

interface DispatchProps {
    fetchConfiguration: typeof actionCreators.configuration.fetchConfiguration;
}

type Props = StateProps & DispatchProps;

class Configuration extends React.PureComponent<Props> {
    async componentDidMount() {
        this.props.fetchConfiguration();
    }

    render() {
        if (this.props.apiHost) {
            return (
                <Helmet>
                    <script src={`${this.props.apiHost}/v2/js`} type="text/javascript" />
                </Helmet>
            );
        }

        return null;
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state) => ({
    apiHost: state.configuration.config.apiHost || null,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators.configuration, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
