import NavMenu from './NavMenu';
import React from 'react';
import Footer from './Footer';
import { connect, MapStateToPropsParam } from 'react-redux';
import { actionCreators, ApplicationState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';

interface StateProps {}

interface DispatchProps {}

type Props = StateProps & DispatchProps;

interface State {}

class Layout extends React.PureComponent<Props, State, { children?: React.ReactNode }> {
    render() {
        return (
            <div className="d-flex flex-column h-100">
                <NavMenu />
                <div className="flex-fill">{this.props.children}</div>
                <Footer />
            </div>
        );
    }
}
const mapStateToProps: MapStateToPropsParam<StateProps, {}, ApplicationState> = (state) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            ...actionCreators.transaction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
