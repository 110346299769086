import Layout from './components/Layout';

import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Home from './pages/Home';
import SDK from './pages/SDK';
import PayLink from './pages/PayLink';
import PayLinkCallback from './pages/PayLinkCallback';
import PayLinkTryIt from './pages/PayLinkTryIt';
import UseCases from './pages/UseCases';
import Configuration from './components/Configuration';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default () => (
    <Layout>
        <Configuration />
        <ToastContainer position="top-right" hideProgressBar={true} style={{ width: 375 }} />
        <Switch>
            <Route path="/sdk" component={SDK} />
            <Route path="/paylink/callback" component={PayLinkCallback} />
            <Route path="/paylink/tryit" component={PayLinkTryIt} />
            <Route path="/paylink" component={PayLink} />
            <Route path="/use-cases" component={UseCases} />
            <Route exact path="/" component={Home} />
            <Redirect to={'/'} />
        </Switch>
    </Layout>
);
