import React from 'react';

export default function SideBySideLayout(props: React.PropsWithChildren<{}>) {
    return (
        <div className="container mt-3 mb-5">
            <div className="row" style={{ height: '100%' }}>
                {props.children}
            </div>
        </div>
    );
}
